:root {
  --main-color: #1d72e9;
  --Light-mode-Grey-02: #d7d8e0;
  --Light-mode-Grey-01: #f5f5f8;
  --Light-mode-Grey-03: #9b9daa;
  --Light-mode-Black: #0f111c;
  --Light-mode-White: #fff;
  --theme-color-bg: #fff;
  --theme-color-grey-1: #f5f5f8;
  --theme-color-grey-2: #d7d8e0;
  --theme-color-grey-3: #9b9daa;
  --theme-color-grey-4: #797b8d;
  --theme-color-grey-5: #e5e5e5;
  --theme-color-primary-text: #0f111c;
  --theme-sidebar-bg: #e5e5e5;
  --theme-input-bg: #fff;
  --theme-card-bg: #fff;
  --theme-box-shadow: 0px 1px 3px 0px rgba(20, 22, 41, 0.1);
  --theme-box-shadow-dropdown: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);
  --theme-box-shadow-drawer: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);
  --theme-table-hover-bg: #f9f9fb;
  --theme-modal-scrim-bg: #e5e5e5;
  --theme-modal-scrim-opacity: 0.4;
  --Light-mode-Grey-04: #797b8d;
}

.react-datepicker {
  font-family: "Roboto", sans-serif;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid var(--Light-mode-Grey-02) !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.react-datepicker__current-month {
  font-weight: 500;
  font-size: 1.2em;
}

.react-datepicker__day,
.react-datepicker__day-name {
  border-radius: 8px;
  font-size: 1em;
  transition:
    background-color 0.2s,
    color 0.2s;
}

.react-datepicker__day:hover {
  background-color: var(--main-color) !important;
  color: var(--theme-color-bg) !important;
}

.react-datepicker__day--selected {
  background-color: var(--main-color) !important;
  color: var(--theme-color-bg) !important;
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
  background-color: #e3f2fd;
}

.react-datepicker__day--disabled {
  color: #bdbdbd;
}

.react-datepicker__triangle {
  display: none;
}
