.answers {
  &__list-item {
    padding: 0 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 12px;
    cursor: pointer;

    &__company {
      display: flex;
      flex: 1;
      align-items: center;
      column-gap: 12px;
      border-bottom: 0.5px solid var(--Light-mode-Grey-02);
      padding: 12px 0;

      &__titles {
        flex: 1;

        &__company-title {
          display: flex;
          align-items: center;
          gap: 4px;

          &__date {
            color: var(--Light-mode-Grey-04);
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.26px;
          }
        }

        &__date {
          margin-top: 4px;
        }
      }

      &__icon {
        margin-left: auto;
      }
    }
  }
}
