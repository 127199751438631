@import "~react-datepicker/dist/react-datepicker.css";

.overview {
  &__header {
    display: flex;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
  }
  &__body {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__infocpm {
      display: flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;
      flex-wrap: wrap;
    }
    &__fmetrics {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      border-radius: 12px;
      border: 0.5px solid var(--Light-mode-Grey-02, #d7d8e0);
      background: var(--Light-mode-White, #fff);
      box-shadow: 0px 1px 3px 0px rgba(20, 22, 41, 0.1);
      &--header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
      &__info {
        display: flex;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 8px;
        background: var(--Light-mode-Grey-01);
      }
    }
  }
  &__card {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;
    border-radius: 12px;
    border: 0.5px solid var(--Light-mode-Grey-02);
    background: var(--Light-mode-White);
    box-shadow: 0px 1px 3px 0px rgba(20, 22, 41, 0.1);
    min-width: 181px;
    &__header {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    &__body {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  &__statistics {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px 24px;
  }
}

.date-pickers {
  display: flex;
  gap: 12px;
}

.date-picker-input {
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid var(--Light-mode-Grey-02);
  font-size: 14px;
  color: #000;
}
.date-picker-input:focus {
  outline: none !important;
}
