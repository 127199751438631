@use 'src/assets/scss/abstracts/mixins';
@use 'src/assets/scss/abstracts/variables';

.tableU {
  //overflow-x: auto;
  width: 100%;
  max-width: 100%;
  &__loading {
    width: 100%;
    height: 4px;
    background-color: #f3f3f3;
    position: relative;
    overflow: hidden;
  }
  &__loading:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include mixins.bg-color(var(--main-color));
    animation: loading 1.5s infinite;
  }
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  &__fixed-header {
    position: relative;

    table thead {
      @include mixins.bg-color(var(--theme-color-bg));
      position: sticky;
      top: -1px;
      z-index: 2;
    }
  }

  &__header {
    @include mixins.color(var(--theme-color-grey-3));
    font-size: 13px;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.13px;
  }

  &__label-value {
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }

  &__column {
    @include mixins.color(var(--theme-color-primary-text));
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;

    &-fixed {
      position: sticky;
      right: -6px;
      padding-right: 17px !important;
    }
  }

  &__loading {
    .v-progress-linear__determinate,
    .v-progress-linear__indeterminate {
      @include mixins.bg-color(variables.$color-main);
    }

    .v-progress-linear__background {
      @include mixins.bg-color(var(--theme-color-grey-1));
      opacity: 1;
    }
  }

  &__no-data {
    text-align: center;
    @include mixins.color(var(--theme-color-grey-3) !important);
  }

  &--stripe table tbody tr:nth-child(even) td {
    @include mixins.bg-color(var(--theme-color-grey-1));
  }
  &--stroke table tbody tr:not(:last-child) td {
    border-bottom: 1px solid var(--theme-color-grey-2);
    @include mixins.border-color(var(--theme-color-grey-2));
  }

  table {
    width: 100%;
    border-collapse: collapse;
    background-color: transparent;
    text-align: left;

    tr {
      &:hover {
        td {
          @include mixins.bg-color(var(--theme-table-hover-bg));

          &.tableU__column-fixed {
            @include mixins.bg-color(var(--theme-table-hover-bg));
          }
        }
      }

      th {
        border-bottom: 1px solid var(--theme-color-grey-2);
        @include mixins.border-color(var(--theme-color-grey-2));

        &.tableU__column-fixed {
          @include mixins.bg-color(var(--Light-mode-Grey-01));
        }
      }

      th:first-child,
      td:first-child,
      th:last-child,
      td:last-child {
        @include mixins.bg-color(var(--theme-color-bg) !important);
        width: 12px;
        min-width: 12px;
        padding: 0;
      }

      th:nth-child(2),
      td:nth-child(2) {
        padding-left: 12px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      th:nth-last-child(2),
      td:nth-last-child(2) {
        padding-right: 12px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    tbody {
      tr {
        td {
          @include mixins.bg-color(var(--theme-color-bg));
        }
        &:hover {
          td {
            @include mixins.bg-color(var(--theme-table-hover-bg));
          }
        }
      }
    }

    th,
    td {
      padding: 12px;
    }
  }
}

:root {
  &.dark-mode,
  &[native-dark-active] {
    .tableU {
      table {
        tr {
          &:hover {
            td {
              @include mixins.bg-color(rgba(variables.$color-grey-1-dark, 0.6));
            }
          }
        }
      }
    }
  }
}
