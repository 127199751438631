.button-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.feedbacksForm {
  padding-bottom: 80px;
  &__header {
    padding: 22px 24px;
    border-bottom: 1px solid var(--Light-mode-Grey-02);
  }
  &__templates {
    min-height: 200px;
    width: 944px;
    margin: 24px auto 0 auto;
    &__header {
      padding: 0 12px;
      &--description {
        margin-top: 16px;
      }
    }
    &__template {
      display: flex;
      width: 100%;
      padding: 16px;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid #f99e2c;
      margin-top: 24px;
      background-color: var(--theme-card-bg);
      gap: 16px;
      &--header {
        display: flex;
        gap: 12px;
        width: 100%;
        img {
          cursor: pointer;
        }
      }
      &--body {
        width: 100%;
        .required {
          color: #f64747;
        }
      }
    }
  }
  &__addSection {
    position: relative;
    margin: 0 auto;
    width: 210px;
    height: 112px;
    background-image: url("./add_section_background.svg");
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align items to the bottom */
    align-items: center;
    background-size: 210px 112px;
  }
  &__footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 240px);
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    gap: 12px;
    button {
      width: 350px;
    }
  }
}
.item {
  padding: 16px;
  margin-bottom: 8px;
  border-top: 0.5px solid var(--Light-mode-Grey-02, #d7d8e0);
  background: var(--Color-Gray-White, #fff);
  border-radius: 4px;
  user-select: none;
}

.item:active {
  background-color: #ddd;
}
