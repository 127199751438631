.progress-bar-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.progress-bar {
  position: relative;
  width: 50px;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 5px;
}

.progress-bar-fill {
  height: 100%;
  border-radius: 5px;
}
.red-bar {
  background-color: #EB5757;
}
.blue-bar {
  background-color: #1D72E9;
}

.red-bar_text {
  color: #EB5757;
}
.blue-bar_text {
  color: #1D72E9;
}
.grey-bar_text {
  color: var(--Light-mode-Grey-03);
}
