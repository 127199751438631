@use 'src/assets/scss/abstracts/mixins';
@use 'src/assets/scss/abstracts/variables';

.drawer-view {
  @include mixins.bg-color(var(--theme-color-bg));
  height: 100%;
  display: flex;
  align-items: flex-start;
  overflow-y: auto;

  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__sidebar {
    @include mixins.bg-color(var(--theme-color-grey-2));
    flex-shrink: 0;
    flex-grow: 0;
    padding: 24px;
    align-self: stretch;
    overflow-y: auto;
    position: sticky;
    top: 0;
    left: 0;
  }

  &__body {
    //position: relative;
    width: 100%;
    align-self: stretch;
  }

  &__header,
  &__footer {
    width: 100%;
    display: inline-block;
    position: sticky;
    left: 0;
    z-index: 1;
    @include mixins.bg-color(var(--theme-color-bg));
  }

  &__header {
    top: 0;
    //border-bottom: 1px solid rgb(var(--v-theme-border));
  }

  &__header-content,
  &__footer-content {
    margin: 20px 24px 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  //&__content {
  //  padding: 24px 0;
  //}

  &__footer {
    bottom: 0;
    // border-top: 1px solid rgb(var(--v-theme-border));
  }
}

.navigation-drawer {
  @include mixins.bg-color(var(--theme-color-bg));
  @include mixins.box-shadow(-40px 0px 100px 0px rgba(0, 0, 0, 0.15));
  border-left: 1px solid var(--theme-color-grey-2);
  @include mixins.border-color(var(--theme-color-grey-2));

  &__overlay .v-overlay__scrim {
    @include mixins.bg-color(var(--theme-color-grey-5) !important);
    opacity: 0.4 !important;
  }
}