.feedback {
  &__header {
    padding: 22px 24px 14px 24px;
  }
}
.mr-0 {
  margin-right: 0 !important;
}
.unread {
  display: inline-block;
  padding: 0 8px;
  align-items: center;
  border-radius: 12px;
  background: var(--main-color);
  color: #fff;
  margin-left: 12px;
}
.unarchive-icon {
  filter: brightness(100);
}