//----------COLORS----------
$color-white: #ffffff;
$color-white__02: rgba($color-white, 0.2);
$color-white__04: rgba($color-white, 0.4);
$color-white__06: rgba($color-white, 0.6);
$color-white__08: rgba($color-white, 0.8);
$color-white__008: rgba($color-white, 0.08);

$color-black-main: #000;
$color-black: #0f111c;
$color-black-2: #181a29;
$color-black-2__015: rgba($color-black-2, 0.15);

$color-grey-5-light: #141629;
$color-grey-5-light__01: rgba($color-grey-5-light, 0.1);
$color-grey-5-light__02: rgba($color-grey-5-light, 0.2);
$color-grey-1-light: #f5f5f8;
$color-grey-1-dark: #1f222b;
$color-grey-2-dark: #292c39;
$color-grey-3: #9b9daa;
$color-grey-3__01: rgba($color-grey-3, 0.1);
$color-grey-3__03: rgba($color-grey-3, 0.3);
$color-grey-4-light: #797b8d;
$color-grey-4-dark: #9ea3b5;

$color-blue: #13172d;
$color-blue__04: rgba($color-blue, 0.4);
$color-blue-2: #2782e4;
$color-blue-2__01: rgba($color-blue-2, 0.1);
$color-blue-2__012: rgba($color-blue-2, 0.12);
$color-blue-3: rgb(32, 120, 215);
$color-blue-3__hover: #1b66b7;
$color-blue-4: #3330da;
$color-blue-5: #2f80ed;

$color-light-blue: #409cff;

$color-main: #1D72E9;
$color-main__01: rgba($color-main, 0.1);
$color-main__hover: #d48625;

$color-orange-006: rgba($color-main, 0.06);
$color-orange-2: #f68900;

$color-green: #27ae60;
$color-green-2: #0d9c24;
$color-green-2__hover: #0b851f;

$color-red: #f64747;
$color-red__01: rgba($color-red, 0.1);
$color-red__hover: #d13c3c;
$color-red-2: #da5a31;

$color-purple: #9b51e0;
$color-purple__hover: #8445be;

// STATUS COLORS
$color-off: #9b9daa;
$color-off__02: rgba($color-off, 0.2);

$color-sb: $color-main;
$color-sb__02: rgba($color-sb, 0.2);

$color-on: $color-light-blue;
$color-on__02: rgba($color-light-blue, 0.2);

$color-dr: $color-green;
$color-dr__02: rgba($color-dr, 0.2);

$table-hover-log-bg: #e5f0fc;
$table-highlighted-bg: $color-main__01;

$breakpoints: (
  'xs': 600px,
  'sm': 960px,
  'md': 1280px,
  'lg': 1920px,
);

//----------THEME----------
$light-theme-colors: (
  'theme-color-bg': $color-white,

  'theme-color-grey-1': $color-grey-1-light,
  'theme-color-grey-2': #d7d8e0,
  'theme-color-grey-3': #9b9daa,
  'theme-color-grey-4': #797b8d,
  'theme-color-grey-5': $color-grey-5-light,
  'theme-color-primary-text': $color-black,

  'theme-sidebar-bg': $color-grey-5-light,

  'theme-input-bg': $color-white,

  'theme-box-shadow': 0px 1px 3px 0px rgba(20, 22, 41, 0.1),
  'theme-box-shadow-dropdown': 0px 40px 100px 0px rgba(0, 0, 0, 0.15),
  'theme-box-shadow-drawer': 0px 40px 100px 0px rgba(0, 0, 0, 0.15),
  'theme-table-hover-bg': '#f9f9fb',

  'theme-modal-scrim-bg': $color-grey-5-light,
  'theme-modal-scrim-opacity': 0.4,
);

$dark-theme-colors: (
  'theme-color-bg': #15171f,

  'theme-color-grey-1': $color-grey-1-dark,
  'theme-color-grey-2': $color-grey-2-dark,
  'theme-color-grey-3': #6e7286,
  'theme-color-grey-4': $color-grey-4-dark,
  'theme-color-grey-5': rgba(#000, 0.7),
  'theme-color-primary-text': $color-white,

  'theme-sidebar-bg': #101118,

  'theme-input-bg': $color-grey-1-dark,

  'theme-box-shadow': 0px 1px 3px 0px rgba(14, 16, 25, 0.4),
  'theme-box-shadow-dropdown': 0px 40px 100px 0px rgba(0, 0, 0, 0.6),
  'theme-box-shadow-drawer': 0px 100px 0px rgba(0, 0, 0, 0.6),
  'theme-table-hover-bg': '#1a1d25',

  'theme-modal-scrim-bg': $color-black-main,
  'theme-modal-scrim-opacity': 0.7,
);

//----------BORDERS----------
$border-sm: 0.5px;
$border: 1px;
$border-lg: 2px;

//----------BORDER-RADIUS----------
$border-radius: 8px;
$border-radius-lg: 12px;
$border-radius-md: 6px;
$border-radius-sm: 4px;
$border-radius-xs: 2px;
