.infoCard {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: rgba(64, 156, 255, 0.1);
  &__title {
    display: flex;
    gap: 4px;
    align-items: center;
    &--indicator {
      width: 8px;
      height: 8px;
      border-radius: 2px;
    }
    &--green {
      background: #27ae60;
    }
    &--orange {
      background: #f99e2c;
    }
    &--blue {
      background: #409cff;
    }
    &--red {
      background: #f64747;
    }
  }
}
