.button-group-tab {
  display: inline-block;
  background: #f0f0f5;
  padding: 2px;
  border-radius: 8px;
}

.button-tab {
  background: none;
  border: none;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  color: #666;
  margin-right: 4px;
  &.active {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #000;
  }
}
.button-tab:last-child {
  margin-right: 0px;
}
