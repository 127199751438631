.staticticsCard {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  border-radius: 12px;
  border: 0.5px solid var(--Light-mode-Grey-02, #d7d8e0);
  background: var(--Color-Gray-White, #fff);

  box-shadow: 0px 1px 3px 0px rgba(20, 22, 41, 0.1);

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
