@use "src/assets/scss/abstracts/mixins";
@use "src/assets/scss/abstracts/variables";

.text-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-bottom: 16px;

  &--focus &__field {
    @include mixins.box-shadow(none);
    @include mixins.border-color(variables.$color-main);
    outline-color: variables.$color-main;
  }

  // &--focus-title &__field {
  //   ::placeholder {
  //     color: var(--Light-mode-Black);
  //     opacity: 1;
  //     transition: 0.3s;
  //   }
  // }

  &--error &__field {
    @include mixins.box-shadow(none);
    @include mixins.border-color(variables.$color-red);
    outline-color: variables.$color-red;
  }

  &--error &__rule-message {
    @include mixins.color(variables.$color-red);
  }

  &--disabled &__label {
    @include mixins.color(var(--theme-color-grey-3));
  }

  &--disabled &__field {
    @include mixins.box-shadow(none);
  }

  &--disabled &__input {
    @include mixins.color(var(--theme-color-grey-3));
  }

  &--readonly &__before,
  &--readonly &__after {
    pointer-events: none;
  }

  &--disabled &__before,
  &--disabled &__after,
  &--disabled &__input::placeholder {
    @include mixins.color(var(--theme-color-grey-2));
    pointer-events: none;
  }

  &--large &__field {
    padding: 0 14px;
    height: 48px;
  }

  &--sm &__field {
    padding: 0 10px;
    height: 36px;
  }

  &--xs &__field {
    padding: 0 10px;
    height: 32px;
  }

  &__top {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__label {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    @include mixins.color(var(--theme-color-primary-text));
    letter-spacing: -0.13px;
  }

  &__extra {
    @include mixins.color(var(--theme-color-grey-3));
    display: flex;
    cursor: pointer;
  }

  &__extra-text {
    text-align: right;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    @include mixins.color((variables.$color-main));
  }

  &__extra-text.btn {
    @include mixins.color(var(--theme-color-primary));
  }

  &__field {
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid var(--theme-color-grey-2);
    @include mixins.border-color(var(--theme-color-grey-2));
    border-radius: variables.$border-radius;
    @include mixins.bg-color(var(--theme-input-bg));
    padding: 0 12px;
    @include mixins.box-shadow(var(--theme-box-shadow));
    outline: 1px solid transparent;
    transition: all 0.2s;
  }

  &__clear {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      @include mixins.color(var(--theme-color-grey-4));
    }

    &:active {
      @include mixins.color(var(--theme-color-primary-text));
    }
  }

  &__before,
  &__after {
    @include mixins.color(var(--theme-color-grey-3));
    display: flex;
  }

  &__before {
    margin-right: 8px;
  }

  &__after:first-child {
    margin-left: 8px;
  }

  &__value-space {
    @include mixins.color(var(--theme-color-primary-text));
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    flex: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__input {
    min-width: 0;
    height: 100%;
    flex: auto;
    border: none;
    background-color: transparent;
    @include mixins.color(var(--theme-color-primary-text));
    line-height: 20px;
    padding: 0;

    &:focus {
      outline: none;
    }

    &::placeholder {
      @include mixins.color(var(--theme-color-grey-3));
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: var(--theme-color-primary-text);
    transition: all 5000000s ease-in-out 0s;
  }

  &__bottom {
    height: 24px;
  }

  &__helper {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    @include mixins.color(var(--theme-color-grey-4));

    display: flex;
    align-items: center;
    gap: 6px;
    // &-icon {
    //   margin-bottom: 2px;
    //   margin-right: 2px;
    // }
  }
}
