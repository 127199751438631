@use 'variables';

@mixin  color($color) {
  --native-dark-font-color: #{$color};

  color: $color;
}

@mixin bg-color($color) {
  --native-dark-bg-color: #{$color};

  background-color: $color;
}

@mixin border-color($color) {
  --native-dark-border-color: #{$color};

  border-color: $color;
}

@mixin box-shadow($box-shadow) {
  --native-dark-box-shadow: #{$box-shadow};

  box-shadow: $box-shadow;
}

// $breakpoint: xs | sm | md | lg
@mixin respond-on($breakpoint) {
  @each $b, $value in variables.$breakpoints {
    @if ($breakpoint == $b) {
      @media screen and (max-width: $value) {
        @content;
      }
    }
  }
}
