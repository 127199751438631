.modal-container {
  border-radius: 4px;
  padding: 52px 24px 24px 24px;
}
.modal-title {
    margin-top: 24px;
}
.modal-subtitle {
    margin-top: 12px;
}
.modal-buttons {
  display: flex;
  width: 100%;
  flex-direction: row;
  column-gap: 12px;
  margin-top: 36px;
  button {
    width: 100%;
  }
}
.modal-icon {
  width: 40px;
}