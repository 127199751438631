@use 'src/assets/scss/abstracts/mixins';
@use 'src/assets/scss/abstracts/variables';

.text-area {
  display: inline-block;
  width: 100%;

  &--focus &__field {
    @include mixins.box-shadow(none);
    @include mixins.border-color(variables.$color-main);
    outline-color: variables.$color-main;
  }

  &--error &__field {
    @include mixins.box-shadow(none);
    @include mixins.border-color(variables.$color-red);
    outline-color: variables.$color-red;
  }

  &--error &__rule-message {
    @include mixins.color(variables.$color-red);
  }

  &--disabled &__label {
    @include mixins.color(var(--theme-color-grey-3));
  }

  &--disabled &__field {
    @include mixins.box-shadow(none);
  }

  &--disabled &__before,
  &--disabled &__after,
  &--disabled &__input::placeholder {
    @include mixins.color(var(--theme-color-grey-2));
  }

  &--large &__field {
    padding: 0 14px;
    height: 48px;
  }

  &--sm &__field {
    padding: 0 10px;
    height: 36px;
  }

  &--xs &__field {
    padding: 0 10px;
    height: 32px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &__label {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    @include mixins.color(var(--theme-color-primary-text));
    letter-spacing: -0.13px;
  }

  &__extra {
    @include mixins.color(var(--theme-color-grey-3));
  }

  &__extra-text {
    text-align: right;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    @include mixins.color(var(--theme-color-primary-text));
  }

  &__field {
    display: flex;
    align-items: center;
    min-height: 80px;
    border: 1px solid var(--theme-color-grey-2);
    @include mixins.border-color(var(--theme-color-grey-2));
    border-radius: variables.$border-radius;
    @include mixins.bg-color(var(--theme-input-bg));
    padding: 6px 12px;
    @include mixins.box-shadow(var(--theme-box-shadow));
    outline: 1px solid transparent;
    transition: all 0.2s;
  }

  &__clear {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      @include mixins.color(var(--theme-color-grey-4));
    }

    &:active {
      @include mixins.color(var(--theme-color-primary-text));
    }
  }

  &__before,
  &__after {
    @include mixins.color(var(--theme-color-grey-3));
    margin-bottom: 4px;
  }

  &__before {
    margin-right: 8px;
  }

  &__after:first-child {
    margin-left: 8px;
  }

  &__value-space {
    @include mixins.color(var(--theme-color-primary-text));
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__input {
    min-width: 0;
    min-height: 68px;
    flex: auto;
    border: none;
    background-color: transparent;
    @include mixins.color(var(--theme-color-primary-text));
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      @include mixins.color(var(--theme-color-grey-3));
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: var(--theme-color-primary-text);
    transition: all 5000000s ease-in-out 0s;
  }

  &__bottom {
    height: 24px;
  }

  &__helper {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    @include mixins.color(var(--theme-color-grey-4));

    &-icon {
      margin-bottom: 2px;
      margin-right: 2px;
    }
  }
}
