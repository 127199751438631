@use "src/assets/scss/abstracts/mixins";
@use "src/assets/scss/abstracts/variables";

.checkbox {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  position: relative;

  &__label {
    text-wrap: nowrap;
  }

  &__box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    @include mixins.box-shadow(var(--theme-box-shadow));
    @include mixins.bg-color(var(--theme-color-bg));
    border: 1px solid var(--theme-color-grey-2);
    @include mixins.border-color(var(--theme-color-grey-2));
    border-radius: variables.$border-radius-sm;
    transition: all 0.2s;
    position: relative;
  }

  &__icon {
    display: flex;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s;
  }

  input {
    display: none;
  }

  input:checked ~ &__box.checkbox--main {
    @include mixins.bg-color(variables.$color-main);
    @include mixins.border-color(variables.$color-main);
    @include mixins.box-shadow(none);
  }

  input:checked ~ &__box.checkbox--purple {
    @include mixins.bg-color(variables.$color-purple);
    @include mixins.border-color(variables.$color-purple);
    @include mixins.box-shadow(none);
  }

  input:checked ~ &__box > &__icon {
    opacity: 1;
  }
}
