@use 'src/assets/scss/abstracts/mixins';

body {
  font-family: 'Inter', sans-serif;
}

.title-large {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.96px; /* -4% letter spacing */
}

.title-1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.36px; /*  -2% letter spacing */
}

.title-2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: -0.32px; /* -2% letter spacing */
}

.title-3 {
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.3px; /* -2% letter spacing */
}

.title-4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px; /* -1% letter spacing */
}

.body-1 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px; /* -1% letter spacing */
}

.body-2 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px; /* -1% letter spacing */
}

.body-3 {
  font-family: 'Geist';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px; /* -1% letter spacing */
}

.body-4 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px; /* -1% letter spacing */
}

.callout-1 {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.13px; /* -1% letter spacing */
}

.callout-2 {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.13px; /* -1% letter spacing */
}

.callout-3 {
  font-family: 'Geist';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.13px; /* -1% letter spacing */
}

.caption-1 {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.26px; /* -1% letter spacing */
}

.caption-2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px; /* -1% letter spacing */
}

.caption-3 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px; /* -1% letter spacing */
}

.caption-4 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.12px; /* -1% letter spacing */
}

.subtitle-1 {
  font-family: 'Geist', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  letter-spacing: -0.13px; /* -1% letter spacing */
}

.subtitle-2 {
  font-family: 'Geist', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
