.sidebar {
    display: flex;
    width: 240px;
    height: 100%;
    position: fixed;
    background-color: #111;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
    border-right: 0.5px solid var(--Light-mode-Grey-02);
    background: var(--Light-mode-Grey-01);
}
.sidebarHeader {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
/* .sidebarHeader img {
    height: 24px;
} */
.sidebarMenu {
    padding: 0 12px;
    /*margin-bottom: 12px;*/
}
.sidebarMenuHead {
    color: var(--Light-mode-Grey-03);
    font-family: 'Geist Mono', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 8px 12px;
}
.sidebarSubMenuHead, .sidebarSubMenuHeadActive {
    display: flex;
    width: 100%;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    color: var(--Light-mode-Black);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    text-decoration: none;
}
.sidebarSubMenuHeadActive {
    border-radius: 8px;
    background: var(--Light-mode-White);
    font-weight: 600;
}