@use 'src/assets/scss/abstracts/mixins';
@use 'src/assets/scss/abstracts/variables';

.tabs {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 24px;
  border-bottom: 1px solid var(--theme-color-grey-2);
  @include mixins.border-color(var(--theme-color-grey-2));
}

.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 4px 16px 0;
  cursor: pointer;
  margin-bottom: -1px;
  border-bottom: 2px solid transparent;
  @include mixins.border-color(transparent);
  transition: all 0.2s;

  &--active {
    @include mixins.border-color(variables.$color-main);
  }

  &__icon {
    @include mixins.color(var(--theme-color-grey-3));
    transition: all 0.2s;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    transition: all 0.2s;
  }

  &--active &__icon {
    @include mixins.color(variables.$color-main);
  }

  &--active &__text {
    font-weight: 600;
  }
}
