.recharts-cartesian-axis-line,
.recharts-cartesian-grid-line,
.recharts-label,
.recharts-line {
  stroke: none;
}
.recharts-layer text {
  color: var(--Light-mode-Grey-03);
  font-family: "Geist Mono", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 84;
  line-height: normal;
  letter-spacing: -0.1px;
}
.recharts-layer line {
  display: none;
}

.recharts-cartesian-grid-horizontal line {
  stroke: var(--Light-mode-Grey-03);
  stroke-dasharray: none;
}

.recharts-cartesian-grid-vertical line {
  stroke: var(--Light-mode-Grey-02);
  stroke-dasharray: 3 3;
  stroke-width: 1px;
}
.recharts-cartesian-grid-vertical line:first-child {
  stroke: none;
  stroke-dasharray: 3 3;
  path {
    display: none !important;
  }
}
.recharts-cartesian-grid-vertical line:last-child {
  stroke: none;
  stroke-dasharray: 3 3;
  path {
    display: none !important;
  }
}
.recharts-text {
  color: var(--Light-mode-Grey-03);
  font-family: "Geist Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: -0.1px;
}
.recharts-tooltip-wrapper {
  .recharts-default-tooltip {
    border: none !important;
    padding: 0 !important;
  }
  background-color: #ffffff;
  //   border: 1px solid #d3d3d3;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
}

.recharts-tooltip-item-list > li {
  color: #333;
}

.recharts-default-tooltip {
  font-family: "Arial, sans-serif";
  font-size: 14px;
}

.recharts-line-curve {
  stroke-width: 2;
}

.custom-tooltip {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
}
