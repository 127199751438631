.answer_container {
  padding: 0 24px;
}
.dynamic-form {
  gap: 24px;
  max-width: 912px;
}
.answer {
  border-top: 0.5px solid var(--Light-mode-Grey-02);
  padding-top: 16px;
}
.answer:first-child {
  border-top: none;
  padding-top: 0;
}
.required {
  display: inline-block;
  color: #F64747;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}