@use 'src/assets/scss/abstracts/mixins';
@use 'src/assets/scss/abstracts/variables';

.auth-card {
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 100%;
  min-height: 100vh;
  position: relative;
}
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  box-shadow: none;
  border-radius: 0;
  --native-dark-bg-color: var(--theme-color-bg);
  background-color: var(--theme-color-bg);
  padding: 16px;
}

.auth-card {
  width: 100%;
  max-width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}