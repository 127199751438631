.radioInput {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.radioLabel,
.darkRadioLabel {
  font-size: 16px;
  font-weight: 400;
  color: #08121d;
  margin-left: 10px;
}

.error {
  position: absolute;
  bottom: -13px;
  font-size: 10px;
  font-weight: 400;
  color: #eb5757;
}

.radio {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  box-shadow: 0px 1px 3px 0px rgba(20, 22, 41, 0.1);
  background-color: #f5f5f8;
  border: 1px solid #d7d8e0;
  border-radius: 50%;
  transition: all 0.2s;
}

.selectedRadio {
  border-width: 6px;
  border-color: var(--main-color);
  background-color: #fff;
  box-shadow: none;
}
