.alert-container {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 380px;
  z-index: 1000;
}

.alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  margin-bottom: 10px;
  border-radius: 2px;
  font-size: 14px;

  &-icon {
    align-self: flex-start;
    margin-right: 12px;
  }

  &-content {
    flex: 1;
  }

  &-close {
    background: none;
    border: none;
    color: inherit;
    font-size: 16px;
    cursor: pointer;
  }

  &-success {
    border: 0.5px solid #27ae60;
    border-left: 3px solid #27ae60;
    background: linear-gradient(
        0deg,
        rgba(39, 174, 96, 0.1) 0%,
        rgba(39, 174, 96, 0.1) 100%
      ),
      #fff;
    box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);
  }

  &-error {
    border: 0.5px solid #f64747;
    border-left: 3px solid #f64747;
    background: linear-gradient(
        0deg,
        rgba(246, 71, 71, 0.1) 0%,
        rgba(246, 71, 71, 0.1) 100%
      ),
      #fff;
    box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);
  }

  &-warning {
    border: 0.5px solid #f68900;
    border-left: 3px solid #f68900;
    background: linear-gradient(
        0deg,
        rgba(246, 137, 0, 0.1) 0%,
        rgba(246, 137, 0, 0.1) 100%
      ),
      #fff;

    box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);
  }

  &-info {
    border: 0.5px solid #409cff;
    border-left: 3px solid #409cff;
    background: linear-gradient(
        0deg,
        rgba(64, 156, 255, 0.1) 0%,
        rgba(64, 156, 255, 0.1) 100%
      ),
      #fff;

    box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);
  }
}
