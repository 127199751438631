.templates {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &__template {
    padding: 16px;
    border-radius: 12px;
    border: 1px solid var(--Light-mode-Grey-02, #d7d8e0);
    background: var(--Color-Gray-White, #fff);
    button {
      display: none;
    }
    &:hover {
      button {
        display: block;
      }
    }
  }
}
